/**
 * This module can be used for show and hiding components using CSS transitions
 * Don't attempt to change the states of these blocks from other modules, instead use the functions provided here
 */

import $ from 'jquery';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import debounce from 'debounce';
import { window } from '../globals';

const ShowHide = {};

ShowHide.constants = {
    attributes: {
        collapsedHeight: 'data-collapsed-height',
        expandedHeight: 'data-expanded-height',
        preventHandler: 'data-prevent-default-handler',
        scrollTarget: 'data-scroll-target',
        toggleEffect: 'data-toggle-effect',
        toggleGroup: 'data-toggle-group',
        toggleOn: 'data-toggle-on',
        togglePointer: 'data-toggle-pointer',
        toggleShorthand: 'data-toggle',
        toggleTarget: 'data-toggle-target',
    },
    classes: {
        collapsed: 'util__collapsible--collapsed',
        collapsible: 'util__collapsible',
        expanded: 'util__collapsible--expanded',
        fadeIn: 'util--fadeIn',
        fadeOut: 'util--fadeOut',
        hidden: 'util--hidden',
        noTransition: 'util--noTransition',
        overflowVisible: 'util__overflow--visible',
        showCollapsed: 'util__show-when-collapsed',
        shown: 'util--shown',
    },
    events: {
        predictiveHeightChanged: 'predictiveHeightChanged',
    },
    properties: {
        collapsedHeight: '--collapsed-height',
        expandedHeight: '--expanded-height',
    },
    selectors: {
        collapsible: '.js-collapsible',
        expandedContent: '.js-expandedContent',
        showCollapsed: '.js-showCollapsed',
        toggle: '.js-toggle',
        toggleTarget: '.js-toggleTarget',
    },
    timing: {
        // In milliseconds
        expand: 200,
    },
};

// An index of all toggle group remainders per toggle item
// to re-index this list use ShowHide.indexToggleGroups()
ShowHide.toggleGroupRemainders = new WeakMap();

ShowHide.init = function onInit() {
    ShowHide.delayedExpansions = new WeakMap();
    ShowHide.initCollapsibles();
    ShowHide.indexToggleGroups();
    if (window) {
        window.ShowHide = ShowHide;
    }
};

/**
 * Sets a CSS custom property and a data-attribute that matches the height of the element
 * based on the height of the .js-expandedContent element inside
 * or the element itself if it does not contain any such element directly
 *
 * @param {HTMLElement|jQuery} element - Any DOM Element
 * @param {boolean} recalculate - If set to true, will always recalculate the expanded height instead of using any precomputed values
 * @param {integer} heightDifference - Positive or negative integer with which to adjust the previously calculated height
 */
const setExpandedHeight = function (element, recalculate = false, heightDifference = undefined) {
    const $element = $(element);
    const previousExpandedHeight = $element.data(ShowHide.constants.attributes.expandedHeight);

    // No need to do anything if we have calculated this before and no recalculation or adjustment was requested
    if (recalculate === false && previousExpandedHeight > 0 && heightDifference === undefined) {
        return;
    }

    let expandedHeight;
    // Recalculate the height if it was requested, or if it had not beed calculated before
    if (recalculate === true || !previousExpandedHeight) {
        // It is recommended to wrap the content in a separate container
        // so that its height can be calculated even when the collapsible container is collapsed
        let expandedContent = $element.children(ShowHide.constants.selectors.expandedContent).first();
        if (expandedContent.length === 0) {
            expandedContent = $element;
        }

        expandedHeight = expandedContent.outerHeight(true);
    } else {
        expandedHeight = previousExpandedHeight + heightDifference;
    }

    if (expandedHeight < 0) {
        expandedHeight = 0;
    }

    if (expandedHeight > 0) {
        $element.data(ShowHide.constants.attributes.expandedHeight, expandedHeight);
        $element.css(ShowHide.constants.properties.expandedHeight, `${expandedHeight}px`);
    }
};

/**
 * @param {HTMLElement|jQuery} target
 *
 * @returns {boolean}
 */
const hasCollapsedHeight = function (target) {
    const $target = $(target);
    const showCollapsed = $target.find(ShowHide.constants.selectors.showCollapsed);

    return showCollapsed.length > 0;
};

/**
 * Sets a CSS custom property and a data-attribute that matches the height of the element
 * based on the height of the .js-showCollapsed element inside.
 * This only applies if a .js-showCollapsed element exists, this is checked using
 * the hasCollapsedHeight function.
 *
 * @param {HTMLElement|jQuery} element - Any DOM Element
 * @param {boolean} recalculate - If set to true, will always recalculate the expanded height instead of using any precomputed values
 * @param {integer} heightDifference - Positive or negative integer with which to adjust the previously calculated height
 */
const setCollapsedHeight = function (element, recalculate = false, heightDifference = undefined) {
    const $element = $(element);
    const previousCollapsedHeight = $element.data(ShowHide.constants.attributes.collapsedHeight);

    // No need to do anything if we have calculated this before and no recalculation or adjustment was requested
    if (recalculate === false && previousCollapsedHeight > 0 && heightDifference === undefined) {
        return;
    }

    let collapsedHeight;
    // Recalculate the height if it was requested, or if it had not been calculated before
    if (recalculate === true || !previousCollapsedHeight) {
        // It is recommended to wrap the content in a separate container
        // so that its height can be calculated even when the collapsible container is collapsed
        const collapsedContent = $element.find(ShowHide.constants.selectors.showCollapsed).first();

        collapsedHeight = collapsedContent.outerHeight(true);
    } else {
        collapsedHeight = previousCollapsedHeight + heightDifference;
    }

    if (collapsedHeight < 0) {
        collapsedHeight = 0;
    }

    if (collapsedHeight > 0) {
        $element.data(ShowHide.constants.attributes.collapsedHeight, collapsedHeight);
        $element.css(ShowHide.constants.properties.collapsedHeight, `${collapsedHeight}px`);
    }
};

/**
 * Sets both the expanded & collapsed height, based on the .js-expandedContent or .js-showCollapsed element
 *
 * @param {HTMLElement|jQuery} element - Any DOM Element
 * @param {boolean} recalculate - If set to true, will always recalculate the expanded height instead of using any precomputed values
 * @param {integer} heightDifference - Positive or negative integer with which to adjust the previously calculated height
 */
const setDynamicHeight = function (element, recalculate = false, heightDifference = undefined) {
    const $element = $(element);
    setExpandedHeight($element, recalculate, heightDifference);
    if (hasCollapsedHeight($element)) {
        setCollapsedHeight($element, recalculate, heightDifference);
    }
};

/**
 * Initialises all values, event listeners, calculates sizes, etc.
 * for all the collapsibles
 */
ShowHide.initCollapsibles = function onInitCollapsibles() {
    const collapsibles = $(ShowHide.constants.selectors.collapsible);
    collapsibles.each(function onEach() {
        const currentCollapsible = $(this);

        // Expose a function on a collapsible element to explicitly recalculate the height
        // sometimes the ResizeSensor doesn't notice the changes
        this.recalculateExpandedHeight = () => {
            setDynamicHeight(currentCollapsible, true);
        };

        // Calculate all expanded height for elements in their current state
        setDynamicHeight(currentCollapsible);

        // Register event listeners to update these calculations whenever descendants update their size
        currentCollapsible.on(ShowHide.constants.events.predictiveHeightChanged, (event, heightDifference) => {
            // Don't update the height if this is the element that initiated the event, this is only for ancestors
            if (event.target === event.currentTarget) {
                return;
            }

            if (heightDifference === undefined) {
                setDynamicHeight(currentCollapsible, true);
            } else {
                setDynamicHeight(currentCollapsible, false, heightDifference);
            }
        });

        // We don't want to update any calculations during transitions, as there would be many updates per second
        let transitionsInProgress = 0;
        let currentTimeout = null;
        currentCollapsible.on('transitionstart transitionrun', (event) => {
            event.stopPropagation();
            // Increase the number of running transitions
            transitionsInProgress += 1;

            // Clear the previous timeout in case this isn't the first transition that was started
            if (currentTimeout !== null) {
                clearTimeout(currentTimeout);
                currentTimeout = null;
            }

            // Not all browsers support the transition events yet so we manually reset the transition state
            // after a set time, just to be sure
            // This also lets us reset the state if the transition was aborted, in which case no event is fired
            currentTimeout = setTimeout(() => {
                // Reset the number of running transitions in case we missed the ending
                transitionsInProgress = 0;
            }, 1000);
        });
        currentCollapsible.on('transitionend transitioncancel', (event) => {
            event.stopPropagation();

            // Clear the timeout since we have already reset the state
            if (currentTimeout !== null) {
                clearTimeout(currentTimeout);
                currentTimeout = null;
            }
            // Reduce the number of running transitions
            transitionsInProgress -= 1;

            if (transitionsInProgress === 0) {
                // Recalculate once the transitions ends
                setDynamicHeight(currentCollapsible, true);
            }
        });

        const setCurrentCollapsibleDynamicHeight = debounce(() => {
            setDynamicHeight(currentCollapsible, true);
        }, 200, true);

        currentCollapsible.children().each((index, child) => {
            // Recalculate dimensions when sizes change
            // e.g. when a device is switched from portrait to landscape mode or a window is resized
            // eslint-disable-next-line no-new
            new ResizeSensor(child, setCurrentCollapsibleDynamicHeight);
        });
    });
};

/**
 * The function indexes all toggle group remainders
 */
ShowHide.indexToggleGroups = function onIndexTogglGroups() {
    ShowHide.toggleGroupRemainders = new WeakMap();
    const toggleItems = $(ShowHide.constants.selectors.toggleTarget).filter(`[${ShowHide.constants.attributes.toggleGroup}]`);
    const toggleGroups = {};

    Array.from(toggleItems).forEach((toggleItem) => {
        const $toggleItem = $(toggleItem);
        const toggleGroupName = $toggleItem.attr(ShowHide.constants.attributes.toggleGroup);
        if (toggleGroupName in toggleGroups) {
            toggleGroups[toggleGroupName].push(toggleItem);
        } else {
            toggleGroups[toggleGroupName] = [toggleItem];
        }
    });

    Object.keys(toggleGroups).forEach((toggleGroupName) => {
        const toggleGroup = Array.from(toggleGroups[toggleGroupName]);
        const $toggleGroup = $(toggleGroup);

        toggleGroup.forEach((toggleItem) => {
            const toggleGroupRemainders = $toggleGroup.not(toggleItem);
            if (toggleGroupRemainders.length >= 1) {
                // We index the remainder based on the actual Element
                // so that different jQuery objects that refer to the same element will all match
                ShowHide.toggleGroupRemainders.set(toggleItem, toggleGroupRemainders);
            }
        });
    });
};

/**
 * Given a jQuery object containing the target elements
 * this returns the remainder from any toggle group as a jQuery object
 *
 * @param {jQuery} $target
 * @return {jQuery}
 */
ShowHide.getToggleGroupRemainders = function onGetToggleGroupRemainders($target) {
    // If we have a remainder ready for this exact jQuery object we return it immediately
    if (ShowHide.toggleGroupRemainders.has($target)) {
        return ShowHide.toggleGroupRemainders.get($target);
    }

    // If we don't have a remainder ready for the jQuery object
    // we might have prepared a remainder for the HTMLElement itself
    if ($target.length === 1 && ShowHide.toggleGroupRemainders.has($target[0])) {
        const remainder = ShowHide.toggleGroupRemainders.get($target[0]);
        // Cache the response for this call
        ShowHide.toggleGroupRemainders.set($target, remainder);

        return remainder;
    }

    if ($target.length > 1) {
        let groupItems = $();
        $target.each(function onEach() {
            const remainder = ShowHide.getToggleGroupRemainders($(this));
            if (remainder.length) {
                groupItems = groupItems.add(remainder);
            }
        });

        const remainder = groupItems.not($target);
        if (remainder.length) {
            ShowHide.toggleGroupRemainders.set($target, remainder);

            return remainder;
        }
    }

    return $();
};

/**
 * @param {string} shortHandString
 * @return {Object}
 */
const getPropertiesFromShorthandString = function (shortHandString) {
    const shortHandProperties = shortHandString.split(' ');

    // The shorthand properties are separated by spaces
    // if the target selector needs to have a space in it, use the target selector attribute to override the shorthand
    const properties = {
        targetSelector: shortHandProperties[0],
    };

    if (shortHandProperties.length > 1) {
        // Remove the first shorthand value which is the target selector
        const remainingProperties = shortHandProperties.slice(1);

        remainingProperties.forEach((property) => {
            // If we have a handler for this property and we don't have an effect yet
            // this is probably an effect
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (property in toggleHandlers && !('effect' in properties)) {
                properties.effect = property;
                return;
            }

            if (property === 'pointer') {
                properties.pointer = true;
            } else if (property === 'prevent-handler') {
                properties.preventBindingHandler = true;
            } else {
                // All that remains is the 'on' property
                properties.on = property;
            }
        });
    }

    return properties;
};

/**
 * @param {jQuery} $toggle
 * @return {{targetSelector: string, effect: string, on: string, pointer: boolean}}
 */
const getToggleProperties = function ($toggle) {
    // Defaults
    const properties = {
        effect: 'showhide-toggle',
        on: 'click',
        pointer: false,
        preventBindingHandler: false,
        targetSelector: '',
    };

    const shorthandString = $toggle.attr(ShowHide.constants.attributes.toggleShorthand);
    const targetSelector = $toggle.attr(ShowHide.constants.attributes.toggleTarget);
    const effect = $toggle.attr(ShowHide.constants.attributes.toggleEffect);
    const on = $toggle.attr(ShowHide.constants.attributes.toggleOn);
    const pointer = $toggle.attr(ShowHide.constants.attributes.togglePointer) === 'true';
    const preventBindingHandler = $toggle.attr(ShowHide.constants.attributes.preventHandler) === 'true';

    // Overwrite the default values with the shorthand values
    if (shorthandString) {
        Object.assign(properties, getPropertiesFromShorthandString(shorthandString));
    }

    // Overwrite the shorthand values and default values with the specific property values
    if (targetSelector) {
        properties.targetSelector = targetSelector;
    }

    if (effect) {
        properties.effect = effect;
    }
    if (on) {
        properties.on = on;
    }
    if (pointer) {
        properties.pointer = pointer;
    }

    properties.preventBindingHandler = preventBindingHandler;

    return properties;
};

/**
 * @param {string} effect
 * @param {jQuery} $target
 *
 * @return function|undefined
 */
const getToggleHandler = function (effect, $target) {
    let handler;

    if ($target.length === 0) {
        return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (effect in toggleHandlers) {
        handler = () => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            toggleHandlers[effect]($target);
        };
    }

    return handler;
};

/**
 * Searches the DOM for elements that want to be toggles for collapsible elements
 * and binds the appropriate handlers
 *
 * @param {HTMLElement|jQuery} container - An element that contains both the toggles and the targets to which all searches are scoped
 */
ShowHide.bindToggles = function onBindToggles(container) {
    const $container = $(container);

    const toggles = $container.find(ShowHide.constants.selectors.toggle);
    toggles.each(function onEach() {
        const currentToggle = $(this);
        const properties = getToggleProperties(currentToggle);

        if (!properties.targetSelector) {
            return;
        }

        const target = $container.find(properties.targetSelector);
        if (target.length === 0) {
            return;
        }

        const clickHandler = getToggleHandler(properties.effect, target);
        if (typeof clickHandler === 'function') {
            if (!properties.preventBindingHandler) {
                currentToggle.on(properties.on, () => {
                    clickHandler();
                });
            }

            // Add the pointer class after binding the handler to hint it is interactive
            if (properties.pointer) {
                currentToggle.addClass('pointer');
            }

            this.triggerShowHideHandler = clickHandler;
        }
    });
};

/**
 * Triggers an event that ancestor elements can subscribe to so calculated values of ancestors can be updated in time
 *
 * @param {HTMLElement|jQuery} target
 * @param {boolean} letAncestorRecalculate
 * @param {string} action
 */
const updateAncestorCalculations = function (target, letAncestorRecalculate, action) {
    const $target = $(target);

    $target.each(function onEach() {
        const currentElement = $(this);
        const isCollapsed = ShowHide.isCollapsed(currentElement);
        let willChange = false;
        const expandedHeight = currentElement.data(ShowHide.constants.attributes.expandedHeight);
        let heightDifference;

        if (letAncestorRecalculate === false) {
            if (isCollapsed && action === 'expand') {
                // If the element is collapsed, the difference in height will be positive
                heightDifference = expandedHeight;
                willChange = true;
            } else if (!isCollapsed && action === 'collapse') {
                // If it was not collapsed, it will be collapsed and the difference will be negative
                heightDifference = -expandedHeight;
                willChange = true;
            }
        }

        if (willChange) {
            // Trigger an event that lets our ancestors know what change is about to take place
            currentElement.trigger(ShowHide.constants.events.predictiveHeightChanged, [heightDifference]);
            // Signal the start of a transition so that we can let the ResizeSensor know that we can ignore these changes
            // We trigger this event manually since not all browsers support this event yet
            currentElement.trigger('transitionrun');
        }
    });
};

/**
 * @param {HTMLElement|jQuery} target
 *
 * @returns {boolean}
 */
ShowHide.isCollapsed = function onIsCollapsed(target) {
    const $target = $(target);

    return $target.is(ShowHide.constants.selectors.collapsible) && $target.hasClass(ShowHide.constants.classes.collapsed);
};

/**
 * Sets the overflow to visible after the expansion is done
 *
 * @param {jQuery} $target
 */
ShowHide.addDelayedExpansion = ($target) => {
    $target.each(function onEach() {
        // eslint-disable-next-line
        const key = this;
        if (ShowHide.delayedExpansions.has(key)) {
            const debouncedExpand = ShowHide.delayedExpansions.get(key);
            debouncedExpand();
            return;
        }

        const $key = $(key);
        const debouncedExpand = debounce(() => {
            $key.addClass(ShowHide.constants.classes.expanded);
        }, ShowHide.constants.timing.expand * 2);

        ShowHide.delayedExpansions.set(key, debouncedExpand);
        debouncedExpand();
    });
};

/**
 * Clears any delayed expansions
 *
 * @param {jQuery} $target
 */
ShowHide.clearDelayedExpansion = ($target) => {
    $target.each(function onEach() {
        // eslint-disable-next-line
        const key = this;
        if (ShowHide.delayedExpansions.has(key)) {
            const debouncedExpand = ShowHide.delayedExpansions.get(key);
            debouncedExpand.clear();
        }
    });
};

/**
 * Use this function to expand elements
 *
 * @param {HTMLElement|jQuery} target
 */
ShowHide.expand = function onExpand(target) {
    const $target = $(target);
    const toggleGroupRemainder = ShowHide.getToggleGroupRemainders($target);

    if (ShowHide.isCollapsed($target)) {
        updateAncestorCalculations($target, false, 'expand');
    }
    if (toggleGroupRemainder.length > 0) {
        updateAncestorCalculations(toggleGroupRemainder, false, 'collapse');
    }

    if (toggleGroupRemainder.length > 0) {
        ShowHide.clearDelayedExpansion(toggleGroupRemainder);
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.expanded);
        toggleGroupRemainder.addClass(ShowHide.constants.classes.collapsed);
    }

    ShowHide.addDelayedExpansion($target);
    $target.removeClass(ShowHide.constants.classes.collapsed);
};

/**
 * Use this function to collapse elements
 *
 * @param {HTMLElement|jQuery} target
 */
ShowHide.collapse = function onCollapse(target) {
    const $target = $(target);
    const toggleGroupRemainder = ShowHide.getToggleGroupRemainders($target);

    if (!ShowHide.isCollapsed($target)) {
        updateAncestorCalculations($target, false, 'collapse');
    }
    if (toggleGroupRemainder.length > 0) {
        updateAncestorCalculations(toggleGroupRemainder, false, 'expand');
    }

    // Remove any delayed expansion still set
    ShowHide.clearDelayedExpansion($target);
    $target.addClass(ShowHide.constants.classes.collapsed);
    $target.removeClass(ShowHide.constants.classes.expanded);

    if (toggleGroupRemainder.length > 0) {
        ShowHide.addDelayedExpansion(toggleGroupRemainder);
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.collapsed);
    }
};

/**
 * Use this function to toggle the collapsed state of elements
 * All elements will receive the same state
 *
 * @param {HTMLElement|jQuery} target
 */
ShowHide.collapseToggle = function onCollapseToggle(target) {
    const $target = $(target);
    const scrollTarget = $target.attr(ShowHide.constants.attributes.scrollTarget);

    if (ShowHide.isCollapsed($target)) {
        ShowHide.expand($target);
    } else {
        ShowHide.collapse($target);
    }

    if (typeof scrollTarget === 'string' && $(scrollTarget).length) {
        $(scrollTarget)[0].scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
};

/**
 * @param {HTMLElement|jQuery} target
 *
 * @returns {boolean}
 */
ShowHide.isFadedOut = function onIsFadedOut(target) {
    const $target = $(target);

    // An element without any of the classes can be considered to be faded in
    // and an element with both classes will be faded out
    return $target.hasClass(ShowHide.constants.classes.fadeOut);
};

/**
 * Applies the correct classes to make an element fade out
 * A faded out element will have opacity: 0 and visibility: hidden
 *
 * @param {HTMLElement|jQuery} target
 */
ShowHide.fadeOut = function onFadeOut(target) {
    const $target = $(target);
    const toggleGroupRemainder = ShowHide.getToggleGroupRemainders($target);

    $target.addClass(ShowHide.constants.classes.fadeOut);
    $target.removeClass(ShowHide.constants.classes.fadeIn);

    if (toggleGroupRemainder.length > 0) {
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.fadeOut);
        toggleGroupRemainder.addClass(ShowHide.constants.classes.fadeIn);
    }
};

/**
 * Applies the correct classes to make an element fade in
 * Works best if the element was faded out instead of simply not visible
 * as it relies on the opacity being 0 to fade the element in
 *
 * @param {HTMLElement|jQuery} target
 */
ShowHide.fadeIn = function onFadeIn(target) {
    const $target = $(target);
    const toggleGroupRemainder = ShowHide.getToggleGroupRemainders($target);

    $target.addClass(ShowHide.constants.classes.fadeIn);
    $target.removeClass(ShowHide.constants.classes.fadeOut);

    // Although show/hide and fade should be used separately
    // trying to fade something in which is hidden should at least result in the target being visible
    if (ShowHide.isHidden($target)) {
        ShowHide.show($target);
    }

    if (toggleGroupRemainder.length > 0) {
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.fadeIn);
        toggleGroupRemainder.addClass(ShowHide.constants.classes.fadeOut);
    }
};

/**
 * @param {HTMLElement|jQuery} target
 */
ShowHide.fadeToggle = function onFadeToggle(target) {
    const $target = $(target);

    if (ShowHide.isFadedOut($target)) {
        ShowHide.fadeIn($target);
    } else {
        ShowHide.fadeOut($target);
    }
};

/**
 * @param {HTMLElement|jQuery} target
 *
 * @return {boolean}
 */
ShowHide.isHidden = function onIsHidden(target) {
    const $target = $(target);

    return $target.hasClass(ShowHide.constants.classes.hidden) || !$target.hasClass(ShowHide.constants.classes.shown);
};

/**
 * @param {HTMLElement|jQuery} target
 */
ShowHide.hide = function onHide(target) {
    const $target = $(target);
    const toggleGroupRemainder = ShowHide.getToggleGroupRemainders($target);

    ShowHide.clearDelayedExpansion($target);
    $target.removeClass(ShowHide.constants.classes.expanded);
    $target.addClass(ShowHide.constants.classes.hidden);
    $target.removeClass(ShowHide.constants.classes.shown);

    if (toggleGroupRemainder.length > 0) {
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.hidden);
        toggleGroupRemainder.addClass(ShowHide.constants.classes.shown);

        if (toggleGroupRemainder.hasClass(ShowHide.constants.classes.collapsible)) {
            ShowHide.addDelayedExpansion(toggleGroupRemainder);
        }
    }
};

/**
 * @param {HTMLElement|jQuery} target
 */
ShowHide.show = function onShow(target) {
    const $target = $(target);
    const toggleGroupRemainder = ShowHide.getToggleGroupRemainders($target);

    $target.removeClass(ShowHide.constants.classes.hidden);
    $target.addClass(ShowHide.constants.classes.shown);
    if ($target.hasClass(ShowHide.constants.classes.collapsible)) {
        ShowHide.addDelayedExpansion($target);
    }

    // Although show/hide, fade and collapse should be used separately
    // trying to show something in which is faded out or collapsed should at least result in the target being visible
    // although no transitions will be used
    ShowHide.disableTransitions($target);

    if (ShowHide.isFadedOut($target)) {
        $target.removeClass(ShowHide.constants.classes.fadeOut);
    }
    if (ShowHide.isCollapsed($target)) {
        $target.removeClass(ShowHide.constants.classes.collapsed);
    }

    // Re-enable transitions afterwards
    debounce(() => {
        ShowHide.enableTransitions($target);
    })();

    if (toggleGroupRemainder.length > 0) {
        ShowHide.clearDelayedExpansion(toggleGroupRemainder);
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.expanded);
        toggleGroupRemainder.addClass(ShowHide.constants.classes.hidden);
        toggleGroupRemainder.removeClass(ShowHide.constants.classes.shown);
    }
};

/**
 * @param {HTMLElement|jQuery} target
 */
ShowHide.showHideToggle = function onShowHideToggle(target) {
    const $target = $(target);

    if (ShowHide.isHidden($target)) {
        ShowHide.show($target);
    } else {
        ShowHide.hide($target);
    }
};

/**
 * Disables transitions on the target
 * @param {HTMLElement|jQuery} target
 */
ShowHide.disableTransitions = function onDisableTransitions(target) {
    const $target = $(target);

    $target.addClass(ShowHide.constants.classes.noTransition);
};

/**
 * Enables transitions on the target
 * @param {HTMLElement|jQuery} target
 */
ShowHide.enableTransitions = function onEnableTransitions(target) {
    const $target = $(target);

    $target.removeClass(ShowHide.constants.classes.noTransition);
};

/**
 * This function is intended to scan the DOM for potentially invalid uses of the ShowHide classes and attributes
 * any mistakes found will be logged to the console
 */
ShowHide.sanityCheck = function onSanityCheck() {
    let noErrors = true;
    const printErrorGroup = (message, elements) => {
        noErrors = false;
        // eslint-disable-next-line no-console
        console.group(message);
        // eslint-disable-next-line no-console
        elements.forEach((element) => console.log(element));
        // eslint-disable-next-line no-console
        console.groupEnd();
    };

    const hasToggleGroup = $(`[${ShowHide.constants.attributes.toggleGroup}]`);
    const missingToggleTarget = [];
    const missingToggleGroupName = [];
    hasToggleGroup.each(function onEach() {
        const $this = $(this);
        if (!$this.is(ShowHide.constants.selectors.toggleTarget)) {
            missingToggleTarget.push(this);
        }
        if (!$this.attr(ShowHide.constants.attributes.toggleGroup)) {
            missingToggleGroupName.push(this);
        }
    });

    if (missingToggleTarget.length) {
        printErrorGroup('The following elements have a toggle-group but are not toggle-targets', missingToggleTarget);
    }
    if (missingToggleGroupName.length) {
        printErrorGroup('The following elements have a toggle-group attribute without a name', missingToggleGroupName);
    }

    if (noErrors) {
        // eslint-disable-next-line no-console
        console.info('✓ No ShowHide inconsistencies have been found!');
    }
};

const toggleHandlers = {
    collapse: ShowHide.collapse,
    'collapse-toggle': ShowHide.collapseToggle,
    expand: ShowHide.expand,
    'fade-in': ShowHide.fadeIn,
    'fade-out': ShowHide.fadeOut,
    'fade-toggle': ShowHide.fadeToggle,
    hide: ShowHide.hide,
    show: ShowHide.show,
    'showhide-toggle': ShowHide.showHideToggle,
};

ShowHide.init();

export default ShowHide;
